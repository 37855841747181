<script setup>
const props = defineProps({
  bannerSlot: {
    type: String,
    default: () => ''
  }
})
const backend = useBackend();
const analytics = useAnalytics();

const banners = ref([]);


onMounted(async () => {
  banners.value = await backend.getBannersForSlot(props?.bannerSlot);
})

const slides = computed(() => {
  return banners.value?.data
    ? banners.value.data.sort(b => b.is_default ? -1 : 0)
    : []
});

const slideEnd = (indexData) => {
  // const index = indexData.currentSlideIndex;
  // analytics.analyticsLayer({
  //   event: 'view_promotion',
  //   creative_name: slides.value[index].photo_alt,
  //   url: slides.value[index].url.toLowerCase(),
  // })
}

const slidesInit = () => {
  // analytics.analyticsLayer({
  //   event: 'view_promotion',
  //   creative_name: slides.value[0].photo_alt,
  //   url: slides.value[0].url.toLowerCase(),
  // })
}

const slideClick = (slide) => {
  analytics.analyticsLayer({
    event: 'select_promotion',
    type: slide.type ? slide.type : '',
    slot: props.bannerSlot ? props.bannerSlot : 'moto-artykul-300x600',
    creative_name: slide.photo_alt,
    link_url: slide.url.toLowerCase(),
  })
}

</script>

<template>
  <div class="relative w-full min-w-[300px] h-[600px]
              rounded-lg shadow-box hover:shadow-box-hover overflow-hidden select-none
              transition-all duration-300 hover:-translate-y-1 cursor-pointer">
    <BannerCarousel :items-to-show="1"
                    :wrap-around="true"
                    :autoplay="slides.length > 1 ? 5000 : 0"
                    :mouseDrag="slides.length > 1"
                    :touchDrag="slides.length > 1"
                    :i18n="{
                    ariaNextSlide: '',
                    ariaPreviousSlide: '',
                    ariaNavigateToSlide: '',
                    ariaGallery: '',
                    itemXofY: ' ',
                    iconArrowUp: ' ',
                    iconArrowDown: ' ',
                    iconArrowRight: ' ',
                    iconArrowLeft: ' '
                    }"
                    @slide-end="(index) => slideEnd(index)"
                    @init="slidesInit"
    >
      <template v-for="(slide,key) in slides" :key="key">
        <BannerSlide >
          <NuxtLink :class="['relative w-full h-[600px]', {'cursor-default': !slide.url}]" :to="slide.url || null"
                    @click="slideClick(slide)">
            <div class="w-full h-full bg-cover bg-center" :style="{'backgroundImage':`url(${slide.photo})`}"></div>
          </NuxtLink>
        </BannerSlide>
      </template>
    </BannerCarousel>
  </div>
</template>
